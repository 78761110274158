import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "broadway-at-the-eccles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#broadway-at-the-eccles",
        "aria-label": "broadway at the eccles permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Broadway at the Eccles`}</h1>
    <h2 {...{
      "id": "digital-publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#digital-publications",
        "aria-label": "digital publications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Digital Publications`}</h2>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    {
      /*-------------------- CURRENT PUBLICATIONS SECTION --------------------*/
    }
    <h2 {...{
      "id": "tonights-show",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tonights-show",
        "aria-label": "tonights show permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tonight’s Show`}</h2>
    <Link to="/hadestown-2022" alt="Link to Hades Broadway at the Eccles web publication." mdxType="Link">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgADAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAfOdydBKGnmkWUr/xAAcEAACAgIDAAAAAAAAAAAAAAABEQACAxAEEjH/2gAIAQEAAQUC8KcQ1YnXWyJsoOTYC2Rklz//xAAYEQADAQEAAAAAAAAAAAAAAAAAESEQEv/aAAgBAwEBPwGZGdI//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AXD/xAAcEAACAgIDAAAAAAAAAAAAAAAAARARAiEgMUH/2gAIAQEABj8C1HvDplZJmyqRbUf/xAAcEAEBAQADAAMAAAAAAAAAAAABEQAxQWEhUZH/2gAIAQEAAT8hnVT3IF+/M5MI8mBOTDDvWCflr6VOzEnwwkyCGcgXzX3f/9oADAMBAAIAAwAAABDvIv7/xAAZEQEAAwEBAAAAAAAAAAAAAAABABEhQXH/2gAIAQMBAT8QwK15BHch0clcBP/EABgRAAMBAQAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/EK2aR8KP/8QAHBABAQEBAQADAQAAAAAAAAAAAREAITFhcbHB/9oACAEBAAE/ELRCAsj+6Ihx5B+GEyL67knASBmAlPOJnhR9GV5CWj7jICBbkfTMCA/JmEBUOk8wwAp8u3+5xRITf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/8ac56/hadestown-cover.webp 240w", "/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/d3be9/hadestown-cover.webp 480w", "/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/87ca7/hadestown-cover.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/09b79/hadestown-cover.jpg 240w", "/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/7cc5e/hadestown-cover.jpg 480w", "/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/1a144/hadestown-cover.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3eeaa3c1cc465b62256dc8e6d02d4b1f/1a144/hadestown-cover.jpg",
              "alt": "Broadway at the Eccles Hadestown cover image",
              "title": "Broadway at the Eccles Hadestown cover image",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Hadestown</div>
    </Link>
    {
      /*-------------------- CURRENT PUBLICATIONS SECTION END--------------------*/
    }
    <h2 {...{
      "id": "past-shows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#past-shows",
        "aria-label": "past shows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Past Shows`}</h2>
    <section className="home-list">
      <Link to="/charlie-2022" alt="Link to Charlie and the chocolate factory Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQME/9oADAMBAAIQAxAAAAHNaroa80qKPq2a01SRlH//xAAbEAEBAAIDAQAAAAAAAAAAAAABAAMTAhESIf/aAAgBAQABBQIxhOMbSQfPJ1ceQGx826cttv/EABoRAAEFAQAAAAAAAAAAAAAAAAABAgMSE0H/2gAIAQMBAT8BrGhkhePpq0//xAAYEQADAQEAAAAAAAAAAAAAAAAAAQIREv/aAAgBAgEBPwF1KOTiG9ZqP//EABkQAAMAAwAAAAAAAAAAAAAAAAAQMQERUf/aAAgBAQAGPwJVcesRQhD/xAAgEAACAQQBBQAAAAAAAAAAAAAAAREhMWFxEEGR4fDx/9oACAEBAAE/Ic5tHyDIEVskqVF3x1BI1WYbbctyeskPkbu5/9oADAMBAAIAAwAAABAsMvz/xAAaEQACAgMAAAAAAAAAAAAAAAAAAREhMVFh/9oACAEDAQE/EHUaOJI5yNTP/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARITH/2gAIAQIBAT8Q46WJ9AgjC//EAB8QAQEBAAEEAwEAAAAAAAAAAAERACExQVFhcZGx8P/aAAgBAQABPxBxsrxM1SoXwCb+E1c1eJPz08YGBFyGh795G8mgkb7nQMAuXQeT6yCgtWYNCsAdeJe18Z//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c9a981deadd1d2db106ff6fb947c85d1/8ac56/CharlieFactoryCover.webp 240w", "/static/c9a981deadd1d2db106ff6fb947c85d1/d3be9/CharlieFactoryCover.webp 480w", "/static/c9a981deadd1d2db106ff6fb947c85d1/87ca7/CharlieFactoryCover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c9a981deadd1d2db106ff6fb947c85d1/09b79/CharlieFactoryCover.jpg 240w", "/static/c9a981deadd1d2db106ff6fb947c85d1/7cc5e/CharlieFactoryCover.jpg 480w", "/static/c9a981deadd1d2db106ff6fb947c85d1/1a144/CharlieFactoryCover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c9a981deadd1d2db106ff6fb947c85d1/1a144/CharlieFactoryCover.jpg",
                "alt": "Broadway at the Eccles Jesus Christ Superstar cover image",
                "title": "Broadway at the Eccles Jesus Christ Superstar cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Charlie and the Chocolate Factory</div>
      </Link>
      <Link to="/jcs-2022" alt="Link to Jesus Christ Superstar Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcZrTRnkhTvT6MRh/8QAGxAAAwEAAwEAAAAAAAAAAAAAAAECEQMSIjL/2gAIAQEAAQUClM6ujqiX5h4cn1NFeRvTRmn/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAABIQAQESAy/9oACAEBAAY/AlYGLZnUen//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhEEExYXFR/9oACAEBAAE/IQgSD+04GKfgnpKpprfcK7BcLmk7lSMtdQtyJIoQhMniSw//2gAMAwEAAgADAAAAEO/GAP/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxBw/8QAFhEAAwAAAAAAAAAAAAAAAAAAAREg/9oACAECAQE/EGY//8QAHhABAAICAQUAAAAAAAAAAAAAAQARITFRQXGBkbH/2gAIAQEAAT8QYeAySD2YiiNVtODwRB3FC0C6DK5RRItZZpdsvWrEH2MAMlIYejcwOnCy/FatheioyFbnZP/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1d588331c4da62f8a6b206c8d4f24222/8ac56/JCSuperstarCover.webp 240w", "/static/1d588331c4da62f8a6b206c8d4f24222/d3be9/JCSuperstarCover.webp 480w", "/static/1d588331c4da62f8a6b206c8d4f24222/87ca7/JCSuperstarCover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1d588331c4da62f8a6b206c8d4f24222/09b79/JCSuperstarCover.jpg 240w", "/static/1d588331c4da62f8a6b206c8d4f24222/7cc5e/JCSuperstarCover.jpg 480w", "/static/1d588331c4da62f8a6b206c8d4f24222/1a144/JCSuperstarCover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1d588331c4da62f8a6b206c8d4f24222/1a144/JCSuperstarCover.jpg",
                "alt": "Broadway at the Eccles Jesus Christ Superstar cover image",
                "title": "Broadway at the Eccles Jesus Christ Superstar cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Jesus Christ Superstar</div>
      </Link>
      <Link to="/bands-visit-2022" alt="Link to The Band’s Visit Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQMAAv/aAAwDAQACEAMQAAABzoYLSXOxGK9CnnJj4n//xAAdEAACAQQDAAAAAAAAAAAAAAAAARECAxASEyIx/9oACAEBAAEFAvCZxoaIqUN9TcgrtoVg4T//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAREv/aAAgBAwEBPwFRTMs//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBL/2gAIAQIBAT8BpSRtH//EABkQAAIDAQAAAAAAAAAAAAAAABAxABEgQf/aAAgBAQAGPwIvlx6Y/8QAHxAAAgICAQUAAAAAAAAAAAAAAAERITFREEFhgZHR/9oACAEBAAE/IV2LyhyWl64a1W6HvQ2qUmJook5obVZk6IWPkCoOkjnAgf/aAAwDAQACAAMAAAAQTzTN/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQMBAT8QXJsScK//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EGXTIK8P/8QAIBABAQABAwQDAAAAAAAAAAAAAREAITFRQXGBoZHB0f/aAAgBAQABPxBUo/kxUQ9omS4YdVfQeWOYYKOvkvJ7MEIKGzkxlKEm7k/dPjBCwoDjZfbCdmAjqPVxBAa1JgPW5//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/afc2864419bd6ad64e557be0c9288982/8ac56/band-cover.webp 240w", "/static/afc2864419bd6ad64e557be0c9288982/d3be9/band-cover.webp 480w", "/static/afc2864419bd6ad64e557be0c9288982/87ca7/band-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/afc2864419bd6ad64e557be0c9288982/09b79/band-cover.jpg 240w", "/static/afc2864419bd6ad64e557be0c9288982/7cc5e/band-cover.jpg 480w", "/static/afc2864419bd6ad64e557be0c9288982/1a144/band-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/afc2864419bd6ad64e557be0c9288982/1a144/band-cover.jpg",
                "alt": "Broadway at the Eccles The Band’s Visit cover image",
                "title": "Broadway at the Eccles The Band’s Visit cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Band’s Visit</div>
      </Link>
      <Link to="/anastasia-2022" alt="Link to Anastasia Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFBAH/xAAXAQEBAQEAAAAAAAAAAAAAAAADAQAC/9oADAMBAAIQAxAAAAHK2xLZhSE54mQJKhLA3//EABoQAAMBAQEBAAAAAAAAAAAAAAECAwASExH/2gAIAQEAAQUCjMHTVaMy8sD8kQo1ZGjdj19jzEBph8Wy2K7/xAAXEQEBAQEAAAAAAAAAAAAAAAAAARES/9oACAEDAQE/AZGNdP/EABgRAAMBAQAAAAAAAAAAAAAAAAABAhED/9oACAECAQE/AXTek3LQpHyrT//EAB8QAAIBBAIDAAAAAAAAAAAAAAABEQIhIkEQMRJRsf/aAAgBAQAGPwKpvpIhjT0LCnLRMOj4eVMXJ9Wgdt7E2kdcWP/EABwQAAICAwEBAAAAAAAAAAAAAAERACExYXFBUf/aAAgBAQABPyH6JWIcU8EJkRKUldq+dhSx3cX3JaPsKwEoUTEYPN1rDUB5KEk0iA1QT//aAAwDAQACAAMAAAAQgxAz/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxATNnLyzAv/xAAZEQEBAQADAAAAAAAAAAAAAAABEQAxUYH/2gAIAQIBAT8QMRTrzCFZok1icb//xAAdEAEBAQEAAgMBAAAAAAAAAAABEQAhMUFRgcGx/9oACAEBAAE/EPA+woe3nnIq+KJHNZ6hfWfozQXRYtVcKPzNN+mEoI2CRz8M9iIN9HOtk/uuiRErFnQiioXCD2LZ3T931lJ0bDf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ad732d932dd584ddcdbfc3ca5d3c0391/8ac56/anastasia-cover.webp 240w", "/static/ad732d932dd584ddcdbfc3ca5d3c0391/d3be9/anastasia-cover.webp 480w", "/static/ad732d932dd584ddcdbfc3ca5d3c0391/87ca7/anastasia-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ad732d932dd584ddcdbfc3ca5d3c0391/09b79/anastasia-cover.jpg 240w", "/static/ad732d932dd584ddcdbfc3ca5d3c0391/7cc5e/anastasia-cover.jpg 480w", "/static/ad732d932dd584ddcdbfc3ca5d3c0391/1a144/anastasia-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/ad732d932dd584ddcdbfc3ca5d3c0391/1a144/anastasia-cover.jpg",
                "alt": "Broadway at the Eccles Anastasia cover image",
                "title": "Broadway at the Eccles Anastasia cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Anastasia</div>
      </Link>
      <Link to="/hamilton-2022" alt="Link to Hamilton Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgME/9oADAMBAAIQAxAAAAGVXnltM1gceTPZNpjEf//EABwQAAICAwEBAAAAAAAAAAAAAAABAgMEEzEUIf/aAAgBAQABBQKOLA8tcx4cUJlDabkWcr+z4bEx2o3n/8QAFxEBAQEBAAAAAAAAAAAAAAAAABIBIf/aAAgBAwEBPwGcS4rH/8QAGhEAAgIDAAAAAAAAAAAAAAAAABIBAhEhUf/aAAgBAgEBPwFrdHk3gST/xAAdEAACAQQDAAAAAAAAAAAAAAAAASECEBEyEjFR/9oACAEBAAY/Au2OWbO1fjeVbkJOMmESiaTU/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUFxMVGR/9oACAEBAAE/ISup3UMjJrKi1RuyJq+CdyxhCppgWaYKPBM3DMPBpsr7fZ//2gAMAwEAAgADAAAAEJTh8P/EABgRAAMBAQAAAAAAAAAAAAAAAAABESFh/9oACAEDAQE/EHhhI6dp0P/EABgRAQEBAQEAAAAAAAAAAAAAAAEAEVFh/9oACAECAQE/EHJYd4DjLxv/xAAgEAEBAQABAwUBAAAAAAAAAAABEQAhMYGhQVFxsdHw/9oACAEBAAE/EE5S9injKKNFAifBnvkH5nRXXcvHQcxPN7ZKP3lihZKx7YX9dSinpMRDD05zIdFQWly+Uqochy6v9e2//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/32e2411e9ffcdf9977e54075df45f592/8ac56/bae-hamilton-cover.webp 240w", "/static/32e2411e9ffcdf9977e54075df45f592/d3be9/bae-hamilton-cover.webp 480w", "/static/32e2411e9ffcdf9977e54075df45f592/87ca7/bae-hamilton-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/32e2411e9ffcdf9977e54075df45f592/09b79/bae-hamilton-cover.jpg 240w", "/static/32e2411e9ffcdf9977e54075df45f592/7cc5e/bae-hamilton-cover.jpg 480w", "/static/32e2411e9ffcdf9977e54075df45f592/1a144/bae-hamilton-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/32e2411e9ffcdf9977e54075df45f592/1a144/bae-hamilton-cover.jpg",
                "alt": "Broadway at the Eccles Hamilton cover image",
                "title": "Broadway at the Eccles Hamilton cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Hamilton</div>
      </Link>
      <Link to="/meangirls-2021" alt="Link to Mean Girls Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAGtPVa0TBfbpExsyO//xAAeEAACAQMFAAAAAAAAAAAAAAABAgADEjIEEBEUIv/aAAgBAQABBQI6hjBXYTstEstAWPmvFvkxst//xAAaEQACAgMAAAAAAAAAAAAAAAAAAhBRERIT/9oACAEDAQE/AcJRzWjSP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExIKH/2gAIAQEABj8CwwxCnS4HAvSoHx//xAAgEAACAgIABwAAAAAAAAAAAAABEQAhMUEQIFFhcZGx/9oACAEBAAE/IdAHiYAPfBxwLP7ETUWdKogpJ6gnYELDu4SToKolBYeeT//aAAwDAQACAAMAAAAQo+6w/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxBBSimx09gwv//EABgRAAIDAAAAAAAAAAAAAAAAAAARARAh/9oACAECAQE/EMFAq//EACMQAQACAgAEBwAAAAAAAAAAAAEAESExEEFRoWFxkbHB0fD/2gAIAQEAAT8QCBpdST3Upn4H7jiFbbmUz27xfNQAUohjzzCRgsOSpZJbAW6LvZXzN8WowG85Lw7PSAWWp1Q8YhW+H//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6557bbcda9be4ab6e45e807f6d857f14/8ac56/meangirls-cover.webp 240w", "/static/6557bbcda9be4ab6e45e807f6d857f14/d3be9/meangirls-cover.webp 480w", "/static/6557bbcda9be4ab6e45e807f6d857f14/87ca7/meangirls-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6557bbcda9be4ab6e45e807f6d857f14/09b79/meangirls-cover.jpg 240w", "/static/6557bbcda9be4ab6e45e807f6d857f14/7cc5e/meangirls-cover.jpg 480w", "/static/6557bbcda9be4ab6e45e807f6d857f14/1a144/meangirls-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/6557bbcda9be4ab6e45e807f6d857f14/1a144/meangirls-cover.jpg",
                "alt": "Broadway at the Eccles Mean Girls cover image",
                "title": "Broadway at the Eccles Mean Girls cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Mean Girls</div>
      </Link>
      <Link to="/frozen-2021" alt="Link to Frozen Broadway at the Eccles publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQC/8QAGAEAAgMAAAAAAAAAAAAAAAAAAQIAAwT/2gAMAwEAAhADEAAAAWF2OLc+oyBFQm6Z2YpIP//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhITESL/2gAIAQEAAQUC1kz0jVifFGUuIsOReppI0kXZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAABMCIf/aAAgBAwEBPwHOuKJpv//EABkRAAEFAAAAAAAAAAAAAAAAAAABAhAREv/aAAgBAgEBPwFWmYs//8QAGxAAAgIDAQAAAAAAAAAAAAAAEDECEQABEkH/2gAIAQEABj8CQQl1L2jtUHjH/8QAHRAAAwACAwEBAAAAAAAAAAAAAAERITFBYXFRkf/aAAgBAQABPyGtKF4VGJd7E/gZyYKr5D6GiLU558EvBU7G2DwJTElp+Dds/9oADAMBAAIAAwAAABD81D//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EFUrC7Z7Zv/EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhUf/aAAgBAgEBPxDZEieFeH//xAAdEAEAAwEAAwEBAAAAAAAAAAABABEhMUGRoVHR/9oACAEBAAE/EBaAvUk22gYU5BrPU/2Jq2uBulMJ4bR7iGWN6eYWAiADfkXCbSvnfN+/ZQbqurmQYZjo1yUwpXPxtynQNFT/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/85d32d7ef5afdb6b8e2ec3b2225833e3/8ac56/frozen-cover.webp 240w", "/static/85d32d7ef5afdb6b8e2ec3b2225833e3/d3be9/frozen-cover.webp 480w", "/static/85d32d7ef5afdb6b8e2ec3b2225833e3/87ca7/frozen-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/85d32d7ef5afdb6b8e2ec3b2225833e3/09b79/frozen-cover.jpg 240w", "/static/85d32d7ef5afdb6b8e2ec3b2225833e3/7cc5e/frozen-cover.jpg 480w", "/static/85d32d7ef5afdb6b8e2ec3b2225833e3/1a144/frozen-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/85d32d7ef5afdb6b8e2ec3b2225833e3/1a144/frozen-cover.jpg",
                "alt": "Broadway at the Eccles Frozen cover image",
                "title": "Broadway at the Eccles Frozen cover image",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Frozen</div>
      </Link>
    </section>
    <br />
    <br />
    <hr />
    <br />
    <p></p>
    <OutboundLink href="http://redledges.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayEccles" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <div className="mills-impression" id="red-ledges-bate">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAFy2M50ATk3GopAbE//xAAaEAADAQEBAQAAAAAAAAAAAAAAAQIRExIi/9oACAEBAAEFAsMMO0b2k9ojXVS5adpT8LtFRTk//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHhAAAQMEAwAAAAAAAAAAAAAAAAECIRAREiIyQUL/2gAIAQEABj8CrEnVJSEMrpsejiXc1DVjT//EAB4QAQADAAICAwAAAAAAAAAAAAEAESFBcRAxYZHR/9oACAEBAAE/IQSvjkLRpV27gx+Ro+imMAsyiVAofCRJXbl32y6sDhLl8xJ1P//aAAwDAQACAAMAAAAQm9dz/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxBqFRVNIj//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhYf/aAAgBAgEBPxBNs0lfDT//xAAeEAEBAQABBAMAAAAAAAAAAAABEQAhQWFxkVGhsf/aAAgBAQABPxCLkb5yfR96OhrJIsEIfeu2QZGP3WgKd1M+8m0YF+O+hAIB9C6IaLJcHQKIHFYEowX1gu8ryqHwG//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/51b9c241d242b6be0d5c894007029aa6/8ac56/red-ledges.webp 240w", "/static/51b9c241d242b6be0d5c894007029aa6/d3be9/red-ledges.webp 480w", "/static/51b9c241d242b6be0d5c894007029aa6/87ca7/red-ledges.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/51b9c241d242b6be0d5c894007029aa6/09b79/red-ledges.jpg 240w", "/static/51b9c241d242b6be0d5c894007029aa6/7cc5e/red-ledges.jpg 480w", "/static/51b9c241d242b6be0d5c894007029aa6/1a144/red-ledges.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/51b9c241d242b6be0d5c894007029aa6/1a144/red-ledges.jpg",
                "alt": "Red Ledges Utah",
                "title": "Red Ledges Utah",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </div>
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="https://www.jerryseinercadillac.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayEccles" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <div className="mills-impression" id="seiner-cadillac-bate">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHCe8EJ0ZnWdUaMlEJf/8QAHBAAAgMAAwEAAAAAAAAAAAAAAQIAAxEEEhMh/9oACAEBAAEFAp8MWl3DcdhKqdcYAzBa1ZUc6T6jr6xrhv8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8BVc//xAAdEAADAAICAwAAAAAAAAAAAAAAARECEAMxEiFB/9oACAEBAAY/AhfCpeiwmSZDxdyoslxu6610f//EABsQAQEBAAMBAQAAAAAAAAAAAAEAESExYUFx/9oACAEBAAE/IcgwB6bV16Ed2HlgED+QEMA+T0juAI6125wzmGjO9SuM/9oADAMBAAIAAwAAABA/0/H/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EMeWZBdhL//EABcRAQEBAQAAAAAAAAAAAAAAABEAASH/2gAIAQIBAT8Q5OwYWrf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFhgVH/2gAIAQEAAT8QX1EMEbK37DVuaN0fmys/UefnUdofXlm1Q3sPsSgZUYSW65XvU7UFLT5kTCJqnq+ohBCVqy3br2MGkn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1b3acfb5f3331a09a8dd36357ebd774a/8ac56/jerry-seiner-cadillac.webp 240w", "/static/1b3acfb5f3331a09a8dd36357ebd774a/d3be9/jerry-seiner-cadillac.webp 480w", "/static/1b3acfb5f3331a09a8dd36357ebd774a/87ca7/jerry-seiner-cadillac.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1b3acfb5f3331a09a8dd36357ebd774a/09b79/jerry-seiner-cadillac.jpg 240w", "/static/1b3acfb5f3331a09a8dd36357ebd774a/7cc5e/jerry-seiner-cadillac.jpg 480w", "/static/1b3acfb5f3331a09a8dd36357ebd774a/1a144/jerry-seiner-cadillac.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1b3acfb5f3331a09a8dd36357ebd774a/1a144/jerry-seiner-cadillac.jpg",
                "alt": "Jerry Seiner Cadillac",
                "title": "Jerry Seiner Cadillac",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </div>
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="https://golearn.utah.edu/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayEccles" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <div className="mills-impression" id="u-u-go-learn-bate">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "154.58333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAe7JXl2GUr6BoNhP/8QAHRAAAgICAwEAAAAAAAAAAAAAAQIAEQMSFCExI//aAAgBAQABBQLftXV5jqtvoGACMK4+NXKXBjUQ+wef/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAEDAQE/Aaaf/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8BI//EACAQAAIBAgcBAAAAAAAAAAAAAAABIQIREBIgMVFhcYH/2gAIAQEABj8CfA1K7IqbLXHFvmGalT6SkbaP/8QAHRABAAMAAwADAAAAAAAAAAAAAQARMSFBYVFx4f/aAAgBAQABPyHvfLuLXGGoS6C9jqFDlHOxE8ob+ZUBo3yp0x4rBqKfUF4iO1MOKn//2gAMAwEAAgADAAAAECgq8P/EABgRAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAEDAQE/EGjkOFZ//8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EDRbkM//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRYXH/2gAIAQEAAT8QpAsdG8L4wUlDR3s9YJUXZW6PuQQjF4Eo5gTQAoBW85iJqN2I0wyGtFsa37MMtpC/oeHuGw+wkLiLNQ7cZa0Oa3iVGiFLZn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e329556915a6c5b5323df107c65a51c4/8ac56/UUGoLearn.webp 240w", "/static/e329556915a6c5b5323df107c65a51c4/d3be9/UUGoLearn.webp 480w", "/static/e329556915a6c5b5323df107c65a51c4/87ca7/UUGoLearn.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e329556915a6c5b5323df107c65a51c4/09b79/UUGoLearn.jpg 240w", "/static/e329556915a6c5b5323df107c65a51c4/7cc5e/UUGoLearn.jpg 480w", "/static/e329556915a6c5b5323df107c65a51c4/1a144/UUGoLearn.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e329556915a6c5b5323df107c65a51c4/1a144/UUGoLearn.jpg",
                "alt": "University of Utah Go Learn program",
                "title": "University of Utah Go Learn program",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </div>
    </OutboundLink>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      